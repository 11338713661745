<template>
  <div class="tabs-material-subtitle no-padding-bottom">
    <Container>
      <h2>Facteurs étudiés</h2>
    </Container>
  </div>

  <div class="view-header" v-if="pageTitle">
    <div class="view-header__header">
      <Btn
        v-if="
          helperService.userHasPermission('entite_add') &&
          $route.name !== 'protocolsShare'
        "
        class="action-btn"
        icon="plus"
        color="primary"
        text="Créer un protocole"
        :to="{
          name: 'entityAdd',
          params: {
            action: 'add',
          },
        }"
      />
    </div>
    <div class="view-header__footer">
      <h1 class="page-title" v-html="pageTitle"></h1>
    </div>
  </div>
  <div class="view-body">
    <Section>
      <Container>
        <ModalitiesTable
          :protocol_id="protocolId"
        >
        </ModalitiesTable>
      </Container>
    </Section>
  </div>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import Container from '@/components/layout/Container.vue'
import ModalitiesTable from '@/views/componentsViews/modalities/ModalitiesTable.vue'

export default {
  name: 'ProtocolStudiedExperimentSubViewView',

  components: {
    ModalitiesTable,
    Container,
    Btn,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    protocol: {
      type: Object,
    },
  },

  data() {
    return {
      // Table
      headers: [],
      staticHeaders: [
        'Désignation',
        'Numéro local',
        'Destruction de la modalité',
        'Type',
        'Partage de la modalité',
      ],

      // Protocol
      protocolId: this.$route.params.id,

      // Modalites
      modalities: [],

      // Factors
      factors: [],
      factorIndex: 0,

      // Passages
      passagesFactors: [],

      // Form
      stades: {},

      // Modals
      modalData: {},
      modal: {
        PERSONNALISE: false,
        SANTEVEGETALE: false,
        FERTILISATION: false,
        SEMENCES: false,

        addModality: false,
      },

      // Repetables
      repeatables: {
        stade: {
          group: [
            {
              label: 'Stade',
              name: 'stade',
              meta: {
                optionKey: 'id',
                optionValue: 'valeur',
                apiEndpoint: null,
                required: true,
              },
            },
          ],
        },
      },
    }
  },

  async mounted() {
    /*
    this.modalities = await this.getModalites()
    this.modalities = this.getSeedsModalities()
    this.headers = await this.getProtocoleFactors()

    this.formatModalities()
    this.sortByOrder(this.modalities)
    this.getPassages()
    this.addSubHeadersToFactor()
    this.getDictionaryFactors()
    this.getStadeOfPassage()
    */
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    /** *********************
     * GETTERS
     ********************* */
    // Protocoles

    // Factors
    async getProtocoleFactors() {
      const response = await this.fetchService.get(
        `protocole/${this.protocolId}/facteur`,
      )
      let headers = await response.data
      headers = this.sortByOrder(headers)

      return headers
    },
    getPassages() {
      this.getPassageModalities(this.headers)
      this.getPassageFactors()
    },
    getPassageFactors() {
      const factors = this.headers.filter(
        (header) => header.type.uid === 'SANTEVEGETALE',
      )
      factors.forEach((factor) => {
        this.fetchService
          .get(`protocole/${this.protocolId}/facteur/${factor.id}/passage`)
          .then((response) => {
            this.passagesFactors.push(response.data)

            const header = this.headers.find((h) => h.ordre === factor.ordre)
            const passage = response.data.find(
              (data) => data.ordre === factor.ordre,
            )

            header.type.designation = `<span>Stade d'application:</span> ${passage.stade.valeur}`
          })
      })
    },

    // Dictionaries
    getDictionaryFactors() {
      this.fetchService.get('dictionnaire/type/facteur').then((response) => {
        this.factors = response.data
      })
    },
    getStadeOfPassage() {
      this.fetchService.get('dictionnaire/referentiel').then((response) => {
        const referential = response.data.filter(
          (referentiel) => referentiel.uid === 'stade',
        )

        this.repeatables.stade.group[0].meta.apiEndpoint = `dictionnaire/referentiel/${referential[0].id}/valeur`
      })
    },

    // Modalities
    async getModalites() {
      const response = await this.fetchService.get(
        `protocole/${this.protocolId}/modalite`, {
          limit: 0,
        },
      )
      return response.data
    },

    getSeedsModalities() {
      this.modalities.forEach((modality) => {
        this.fetchService
          .get(`protocole/${this.protocolId}/modalite/${modality.id}/semence`, {
            limit: 0,
          })
          .then((semenceModalities) => {
            if (semenceModalities.data.length > 0) {
              // eslint-disable-next-line no-param-reassign
              modality.semences = semenceModalities.data
            }
          })
      })

      return this.modalities
    },
    getPassageModalities(factors) {
      factors.forEach((factor) => {
        this.fetchService
          .get(`protocole/${this.protocolId}/facteur/${factor.id}/variable`)
          .then((semenceModalities) => {
            if (semenceModalities.data.length > 0) {
              // eslint-disable-next-line no-param-reassign
              factor.variable = semenceModalities.data
            }
          })
      })
    },

    formatModalities() {
      const datas = []
      this.modalities.forEach((modality) => {
        let data = {}
        this.headers.forEach((header) => {
          switch (header.type.uid) {
            case 'SANTEVEGETALE':
              data = {
                ...data,
                ...{
                  produit_santevegetale: this.checkIfPropertyExists(
                    'santevegetale',
                    modality,
                    'produit.nom',
                  ),
                  dose_santevegetale: this.checkIfPropertyExists(
                    'santevegetale',
                    modality,
                    'dose',
                  ),
                  unite_santevegetale: this.checkIfPropertyExists(
                    'santevegetale',
                    modality,
                    'unite.valeur',
                  ),
                },
              }
              break
            case 'SEMENCES':
              data = {
                ...data,
                ...{
                  variete_semences: this.checkIfPropertyExists(
                    'semences',
                    modality,
                    'variete',
                  ),
                  traitementsemence_semences: this.checkIfPropertyExists(
                    'semences',
                    modality,
                    'traitementsemence',
                  ),
                  densite_semences: this.checkIfPropertyExists(
                    'semences',
                    modality,
                    'densite',
                  ),
                  pmg_semences: this.checkIfPropertyExists(
                    'semences',
                    modality,
                    'pmg',
                  ),
                  unite_semences: this.checkIfPropertyExists(
                    'semences',
                    modality,
                    'unite.valeur',
                  ),
                  produit_semences: this.checkIfPropertyExists(
                    'semences',
                    modality,
                    'produit.nom',
                  ),
                },
              }
              break
            case 'FERTILISANT':
              data = {
                ...data,
                ...{
                  produit_fertilisant: this.checkIfPropertyExists(
                    'fertilisant',
                    modality,
                    'produit.nom',
                  ),
                  dose_fertilisant: this.checkIfPropertyExists(
                    'fertilisant',
                    modality,
                    'dose',
                  ),
                  unite_fertilisant: this.checkIfPropertyExists(
                    'fertilisant',
                    modality,
                    'unite.valeur',
                  ),
                },
              }
              break
            case 'PERSONNALISE':
              data = {
                ...data,
                ...{
                  produit_personnalise: this.checkIfPropertyExists(
                    'personnalise',
                    modality,
                    'produit.nom',
                  ),
                  dose_personnalise: this.checkIfPropertyExists(
                    'personnalise',
                    modality,
                    'dose',
                  ),
                  unite_personnalise: this.checkIfPropertyExists(
                    'personnalise',
                    modality,
                    'unite.valeur',
                  ),
                },
              }
              break
            default:
              break
          }
        })
        data = {
          ...data,
          ...{
            id: modality.id,
            ordre: modality.ordre,
            designation: modality.designation,
            numero: modality.numero,
            destruction: modality.destruction,
            type: modality.type?.designation,
            partage_accepte:
              this.protocol.partage_accepte !== null ? 'Oui' : 'Non',
          },
        }
        datas.push(data)
      })

      this.modalities = datas
    },
    addSubHeadersToFactor() {
      this.headers.forEach((header) => {
        let subHeaders
        switch (header.type.uid) {
          case 'SEMENCES':
            subHeaders = [
              'Variété',
              'Traitement semence',
              'Densité',
              'Pmg',
              'Unite',
              'Produit',
            ]
            break
          default:
            subHeaders = ['Produit', 'Dose', 'Unité']
        }

        if (subHeaders) {
          // eslint-disable-next-line no-param-reassign
          header.sub_headers = subHeaders
        }
      })

      this.sortByOrder(this.headers)
    },

    /** *********************
     * Helpers
     ********************* */
    getOrder(elements) {
      return Math.max(...elements.map((element) => element.ordre)) + 1
    },
    sortByOrder(element) {
      return element.sort((a, b) => a.ordre - b.ordre)
    },
    checkFactorHeader(factor) {
      return this.headers.map((header) => header.type.uid).includes(factor)
    },
    getFactorOrder(factor) {
      return this.factors.find((f) => f.ordre === factor)
    },
    checkIfPropertyExists(property, obj, element) {
      // console.log((property === 'semences') ? 'semences presentes' : 'pas de semences')
      if (obj[property] && obj[property].length) {
        // console.log((property === 'semences') ? 'semences presentes' : 'pas de semences')
        const handleProperty = (item, prop) => item.split('.').reduce((p, c) => (p && p[c]) || null, prop)
        const value = obj[property].map((p) => handleProperty(element, p))
        if (value.length > 1) {
          return value.join(', ')
        }
        return value[0]
      }
      return '—'
    },
  },
}
</script>

<style scoped>
</style>
